import { Auth } from "aws-amplify";
import {
  createContext,
  createEffect,
  createResource,
  createSignal,
  Show,
  type JSX,
} from "solid-js";
import { createStore } from "solid-js/store";
import { useLocation, useNavigate } from "@solidjs/router";
import { User } from "../helpers/types";

export const UserContext = createContext<User>();

interface UserProviderProps {
  children: JSX.Element;
}

export function UserProvider(props: UserProviderProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, { refetch: refetchUserData }] = createResource(() =>
    Auth.currentAuthenticatedUser(),
  );
  const [accessToken, setAccessToken] = createSignal<string>();
  const [userState, setUserState] = createStore<User>({
    accessToken: "",
    email: undefined,
    userName: "",
    refetchUserData,
  });

  createEffect(() => {
    console.log("Location:", location.pathname);

    const mustBeSignedOut =
      location.pathname.startsWith("/sign-in") ||
      location.pathname.startsWith("/sign-out") ||
      location.pathname.startsWith("/sign-up");

    //If not logged in
    if (userData.error) {
      // "The user is not authenticated"
      if (location.pathname === "/") {
        console.log("Not logged in: both signed in/not signed in acceptable");
        console.log("Reseting user state");
        setUserState({
          accessToken: "",
          email: undefined,
          userName: "",
        });
        return;
      } else if (!mustBeSignedOut) {
        console.log("Not logged in: only signed in acceptable");
        console.log("redirecting to sign in [1]");
        navigate("/sign-in", { replace: true });
        return;
      }
      return;
    } else if (userData.loading) {
      console.log("Loading userdata");
      return;
    } else if (!userData()) {
      console.log("No userdata");
      return;
    } else {
      console.log("Userdata OK");
    }

    //If logged in
    const token = userData()?.signInUserSession?.accessToken?.jwtToken;
    const userName = userData()?.username;

    if (token && userName) {
      setAccessToken(token);
      setUserState({
        accessToken: accessToken(),
        email: userData()?.attributes?.email,
        userName: userName,
      });

      if (mustBeSignedOut) {
        console.log("Signed in: only signed out is acceptable");
        console.log("Redirecting to dashboard");
        navigate("/dashboard", { replace: true });
        return;
      }

      if (location.pathname === "/") {
        navigate("/dashboard", { replace: true });
        return;
      }
    } 
    else {
      if (!mustBeSignedOut) {
        console.log(
          "Signed in, but token or username missing: only signed in is acceptable",
        );
        console.log("redirecting to sign in [2]");
        navigate("/sign-in", { replace: true });
      }
    }
  });

  return (
    <Show
      when={
        accessToken() ||
        location.pathname === "/" ||
        location.pathname.startsWith("/sign-in") ||
        location.pathname.startsWith("/sign-out") ||
        location.pathname.startsWith("/sign-up")
      }
    >
      <UserContext.Provider value={userState}>
        {props.children}
      </UserContext.Provider>
    </Show>
  );
}
