//Defines importable graphql mutation queries with chosen return fields

import { gql } from "@solid-primitives/graphql";

export const _CreateTask = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
    }
  }
`;

export const _DeleteTask = gql`
  mutation DeleteTask($input: DeleteTaskInput!) {
    deleteTask(input: $input)
  }
`;

export const _UpdateTask = gql`
  mutation UpdateTask($updateTaskId: ID!, $input: UpdateTaskInput!) {
    updateTask(id: $updateTaskId, input: $input) {
      id
      description {
        description
      }
    }
  }
`;

export const _CreateMachine = gql`
  mutation CreateMachine($input: CreateMachineInput!) {
    createMachine(input: $input) {
      id
      name
      createdAt
    }
  }
`;

export const _DeleteMachine = gql`
  mutation DeleteMachine($input: DeleteMachineInput!) {
    deleteMachine(input: $input)
  }
`;

export const _UpdateMachine = gql`
  mutation UpdateMachine($updateMachineId: ID!, $input: UpdateMachineInput!) {
    updateMachine(id: $updateMachineId, input: $input) {
      id
      name
      config
    }
  }
`;

export const _CreateSource = gql`
  mutation CreateSource($input: CreateSourceInput!) {
    createSource(input: $input) {
      id
    }
  }
`;

export const _DeleteSource = gql`
  mutation DeleteSource($input: DeleteSourceInput!) {
    deleteSource(input: $input)
  }
`;

export const _UpdateSource = gql`
  mutation UpdateSource($updateSourceId: ID!, $input: UpdateSourceInput!) {
    updateSource(id: $updateSourceId, input: $input) {
      id
    }
  }
`;

export const _CreateDataset = gql`
  mutation CreateDataset($input: CreateDatasetInput!) {
    createDataset(input: $input) {
      annotationType
      name
    }
  }
`;

export const _DeleteDataset = gql`
  mutation DeleteDataset($input: DeleteDatasetInput!) {
    deleteDataset(input: $input)
  }
`;

export const _UpdateDataset = gql`
  mutation UpdateDataset($updateDatasetId: ID!, $input: UpdateDatasetInput!) {
    updateDataset(id: $updateDatasetId, input: $input) {
      id
    }
  }
`;

export const _CreateClassLabel = gql`
  mutation CreateClassLabel($input: CreateClassLabelInput!) {
    createClassLabel(input: $input) {
      id
      name
      color
    }
  }
`;

export const _DeleteClassLabel = gql`
  mutation DeleteClassLabel($input: DeleteClassLabelInput!) {
    deleteClassLabel(input: $input)
  }
`;

export const _UpdateClassLabel = gql`
  mutation UpdateClassLabel(
    $updateClassLabelId: ID!
    $input: UpdateClassLabelInput!
  ) {
    updateClassLabel(id: $updateClassLabelId, input: $input) {
      ClassLabel_belongsToDatasetID
      color
      modelClassId
      name
    }
  }
`;

export const _CreateImageContainer = gql`
  mutation CreateImageContainer($input: CreateImageContainerInput!) {
    createImageContainer(input: $input) {
      id
    }
  }
`;

export const _CreateDatasetsUsedByModel = gql`
  mutation CreateDatasetsUsedByModel($input: CreateDatasetsUsedByModelInput!) {
    createDatasetsUsedByModel(input: $input) {
      id
      createdAt
      updatedAt
      Dataset {
        id
        name
        updatedAt
        images {
          items {
            id
          }
        }
      }
    }
  }
`;

export const _DeleteDatasetsUsedByModel = gql`
  mutation DeleteDatasetsUsedByModel($input: DeleteDatasetsUsedByModelInput!) {
    deleteDatasetsUsedByModel(input: $input)
  }
`;

export const _CreateModel = gql`
  mutation CreateModel($input: CreateModelInput!) {
    createModel(input: $input) {
      id
    }
  }
`;

export const _DeleteModel = gql`
  mutation DeleteModel($input: DeleteModelInput!) {
    deleteModel(input: $input)
  }
`;

export const _UpdateModel = gql`
  mutation UpdateModel($updateModelId: ID!, $input: UpdateModelInput!) {
    updateModel(id: $updateModelId, input: $input) {
      id
      name
      datasets {
        items {
          id
          Dataset {
            id
            name
          }
        }
      }
    }
  }
`;
